import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "how-to-get-involved",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-get-involved",
        "aria-label": "how to get involved permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to get involved`}</h2>
    <h3>{`Welcome to the community  🎉`}</h3>
    <p>{`By designing and developing digital experiences using Sainsbury's Design System, you're already part of the community - so welcome! 🎉`}</p>
    <p>{`To get us started, we have put together three key principles that we ask of the community to ensure that the design system remains useful, up-to-date, and easy to use for all:`}</p>
    <h3>{`Our community principles`}</h3>
    <h4>{`🧰 Start with what we have`}</h4>
    <p>{`Reuse as much of the design system as possible, you can start by checking out what exists in Figma, Storybook, or on this guidelines site.`}</p>
    <h4>{`🧠 Contribute with others in mind`}</h4>
    <p>{`When making a contribution to the design system as a designer or engineer, think about how your contribution will be used by other teams and aim to build something that is scalable, reusable and can evolve over time.`}</p>
    <p>{`Be open to feedback or changes to your work in this process to ensure it works for everyone.`}</p>
    <p>{`Be kind, contributions are celebrated and the design system couldn't evolve without the help of real customer experience problems and ideas to solve.`}</p>
    <h4>{`🤗 Sharing is caring`}</h4>
    <p>{`To evolve and grow the design system, we encourage everyone to be open and honest. Sharing work in progress projects as early as possible, using one of our participation channels, makes it easier to find and reduce duplication of effort across product teams.`}</p>
    <h3>{`Reach out`}</h3>
    <p>{`If you're using or just getting ready to use the design system, we have a number of participation channels open to help you.`}</p>
    <GridList columns="1" mdxType="GridList">
  <GridListItem title="Join the conversation on Slack" titleElement="h3" linkText="Go to #design-systems-hub" linkTo="https://sainsburys-tech.slack.com/archives/C0109KPBCR0" border mdxType="GridListItem">
    Ask a question or join in with design system discussions in our Slack channel (#design-systems-hub). We also have dedicated channels for designers (#design-system-design) and developers (#design-system-engineering) to help ask more specific discipline questions.
  </GridListItem>
    </GridList>
    <GridList columns="1" mdxType="GridList">
  <GridListItem title="Attend our fortnightly drop-in sessions" titleElement="h3" border mdxType="GridListItem">
      Share what contributions your working on or listen along to the design system updates across design and engineering.
    </GridListItem>
  </GridList>
  <GridList columns="1" mdxType="GridList">
    <GridListItem title="Propose an update" titleElement="h3" linkText="Go to #design-systems-hub" linkTo="https://sainsburys-tech.slack.com/archives/C0109KPBCR0" border mdxType="GridListItem">
      Need something new, or something updated but don't have the time to contribute it? No problem - propose your update to the Design System Team using the Request Form in our Slack channel (#design-systems-hub).
    </GridListItem>
  </GridList>
    <h3>{`Contribution`}</h3>
    <p>{`Our design system is built by Sainsbury's, for Sainsbury's. That is why we welcome contributions from the wider business to help us evolve and improve our design system. As part of this, we ensure that contributions are delivered to a high quality and meets the user needs.`}</p>
    <p>{`To guarantee this, we have put together a design and engineering contribution criteria all components and patterns need to meet certain criteria and we have put together.`}</p>
    <GridList columns="1" mdxType="GridList">
  <GridListItem title="For Designers" titleElement="h3" linkText="Contribution for Designers" linkTo="/contribution/for-designers" filled mdxType="GridListItem">
    How to contribute a new component or pattern in Figma?
  </GridListItem>
    </GridList>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our design team are on hand to answers questions or help with your project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      